module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
if (!desc) var desc = '';
if (showbtn === undefined) var showbtn = true;
;
__p += '\r\n<section class="ny-banner pdl ' +
((__t = ( showbtn ? '':'hide-link' )) == null ? '' : __t) +
'">\r\n  <div class="bg-img" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></div>\r\n  <div class="mycontainer">\r\n    <div class="abs">\r\n      <div>\r\n        <h1 class="wow fadeInUp">' +
((__t = ( title )) == null ? '' : __t) +
'</h1>\r\n        <p class="wow fadeInUp">' +
((__t = ( desc )) == null ? '' : __t) +
'</p>\r\n        <div class="link wow fadeInUp"><a href="#" class="common-btn" onclick="apply()">免费试用</a></div>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</section>';

}
return __p
}