module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n  <div class="footer">\r\n    <div class="left">\r\n      <div class="footer-logo"><a href="index.html"><img src="' +
((__t = (require('../../assets/images/logo.svg'))) == null ? '' : __t) +
'" alt=""></a></div>\r\n      <div class="footer-info">\r\n        <p>商务支持：<a href="mailto:business@sudoprivacy.com">business@sudoprivacy.com</a></p>\r\n        <p>联系地址：北京市海淀区成府路28号优盛大厦A座18层1801</p>\r\n      </div>\r\n      <div class="footer-ewm">\r\n        <div class="item"><img src="' +
((__t = (require('../../assets/images/ewm_img1.jpg'))) == null ? '' : __t) +
'" alt="">关注数牍科技公众号</div>\r\n        <div class="item"><img src="' +
((__t = (require('../../assets/images/ewm_img2.jpg'))) == null ? '' : __t) +
'" alt="">关注官方客服</div>\r\n      </div>\r\n    </div>\r\n    <div class="right">\r\n      <div class="footer-nav">\r\n        <ul>\r\n          <li>\r\n            <a href="product_list.html" class="title-link">产品服务</a>\r\n            <a href="pro_tusita.html">Tusita隐私计算平台</a>\r\n            <a href="pro_soft_and_hard.html">软硬协同隐私计算产品</a>\r\n            <a href="pro_oneid.html">ONEID产品</a>\r\n            <a href="pro_datadome.html">Datadome数据资产管控平台</a>\r\n          </li>\r\n          <li>\r\n            <a href="solution_list.html" class="title-link">解决方案</a>\r\n            <a href="solution_bank_marketing.html">智慧银行</a>\r\n            <a href="solution_insurance.html">智慧保险</a>\r\n            <a href="solution_government.html">智慧政务</a>\r\n          </li>\r\n          <li>\r\n            <a href="ecology.html" class="title-link">生态共赢</a>\r\n          </li>\r\n          <li>\r\n            <a href="about.html" class="title-link">关于数牍</a>\r\n            <a href="about.html">公司概况</a>\r\n            <a href="article_list.html">新闻动态</a>\r\n            <a href="contact.html">联系我们</a>\r\n          </li>\r\n        </ul>\r\n      </div>\r\n      <div class="btn">\r\n        <a href="#" class="common-btn" onclick="apply()">免费试用 <i class="iconfont icon-qianbi"></i></a>\r\n      </div>\r\n    </div>\r\n  </div>\r\n  <div class="copyright">\r\n    <div class="left">\r\n      <a href="join_us.html">加入我们</a>\r\n      <a href="contact.html">联系我们</a>\r\n      <a href="#">法律声明</a>\r\n    </div>\r\n    <div class="right">\r\n      © 2022 数牍科技 sudoprivacy.com     \r\n      <a href="#">京ICP备20011614号</a>\r\n      <a href="#">京公网安备 11010802035764号</a>\r\n    </div>\r\n  </div>\r\n</footer>';

}
return __p
}