import './index.scss'

$('.header-aside .openmenu-icon').on('click',function(){
  $('.hidden-nav-c').addClass('active')
  $('.shadenone').show()
})
$('.hidden-nav-c .close-menu').on('click',function(){
  $('.hidden-nav-c').removeClass('active')
})
$('.shadenone').on('click',function(){
  $('.hidden-nav-c').removeClass('active')
  $('.shadenone').hide()
})
$('.header-aside .back-top').on('click',function(){
  $("html, body").animate({
    scrollTop: 0
  }, {
      duration: 500,
      easing: "swing"
  });
  return false;
})

$('.hidden-nav .left ul li .link-title .iconfont').on('click',function(){
  let $parentsEL = $(this).parents('li')
  if($parentsEL.hasClass('active')){
    $parentsEL.removeClass('active').find('.sub-nav').slideUp()
  }else{
    $('.hidden-nav .left ul li').removeClass('active').find('.sub-nav').slideUp()
    $parentsEL.addClass('active').find('.sub-nav').slideDown()
  }
})

$(document).scroll(function () {
  let scroH = $(document).scrollTop(); //滚动高度
  let viewH = $(window).height(); //可见高度
  let contentH = $(document).height(); //内容高度
  let footerTop = $('footer').offset().top
  let footerH = $('footer').outerHeight()

  if (scroH > footerTop - viewH && $(window).width() > 767) {
    $('header .header-aside').addClass('abs').css('bottom', footerH+'px')
  }else{
    $('header .header-aside').removeClass('abs').css('bottom', '0px')
  }
});