
import {WOW} from '../../../node_modules/wowjs/dist/wow';
import Swiper from 'swiper/dist/js/swiper.min.js'
import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import './jquery.navScroll';
new WOW().init();

//内页导航效果
$(document).ready(function(){
  if($('.common-ny-nav').length > 0){
    $('.common-ny-nav .zhanwei').css('height', $('.common-ny-nav .ny-nav').outerHeight() +'px')
    if(!$('.common-ny-nav').hasClass('not-nchor')){
      $('.common-ny-nav .nav').navScroll({
        mobileDropdown: false,
        mobileBreakpoint: 768,
        scrollSpy: true
      });
    }
  }
})

$(window).resize(function(){
  if($('.common-ny-nav').length > 0){
    $('.common-ny-nav .zhanwei').css('height', $('.common-ny-nav .ny-nav').outerHeight() +'px')
  }
})

$(document).scroll(function () {
  let scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if($('.common-ny-nav').length > 0){
    let nyNavH = $('.common-ny-nav').offset().top
    if(scroH > nyNavH){
      $('.common-ny-nav').addClass('fixed')
    }else{
      $('.common-ny-nav').removeClass('fixed')
    }
  }
});

//产品荣誉资质
if($('.pro-common-honor').length > 0){
  var proHonorSwiper = new Swiper(".pro-common-honor .mySwiper", {
    slidesPerView: 4,
    spaceBetween: 80,
    slidesPerGroup: 4,
    centeredSlides: false,
    slidesPerGroupSkip: 1,
    pagination: {
      el: ".pro-common-honor .swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      1366: {
        slidesPerView: 4,
        spaceBetween: 40,
        slidesPerGroup: 4,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
        slidesPerGroup: 3,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
        slidesPerGroup: 2,
      },
      
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
        slidesPerGroup: 1,
      },
    },
  });
}